






































































































































































































































































































































@import '@design';

.resto-editable {
  cursor: text;
  .ProseMirror:focus {
    outline: none;
  }
  &.ph:empty::before {
    display: block; /* For Firefox */
    font-style: italic;
    color: darken(white, 40%);
    pointer-events: none;
    content: attr(placeholder);
  }
}

.pm-ML .pm-el > p {
  margin-top: 0;
  margin-bottom: 0;
}

.is-dirty {
  position: relative;
  &::after {
    position: absolute;
    bottom: 0;
    left: -7px;
    width: 5px;
    height: 100%;
    // bottom:-5px;
    // left:0;
    // width:100%;
    // height:5px;
    content: '';
    background-color: $color-db-orange;
  }
}

.is-editor-empty:first-child::before {
  float: left;
  height: 0;
  font-style: italic;
  color: #aaa;
  pointer-events: none;
  content: attr(data-empty-text);
}

.menububble {
  position: absolute;
  display: -webkit-box;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}
.menububble.is-active {
  opacity: 1;
  visibility: visible;
}
.menububble__button {
  display: -webkit-inline-box;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
}
.menububble__button:last-child {
  margin-right: 0;
}
.menububble__button:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.menububble__button.is-active {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.menububble__form {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: #fff;
}
