











































































.theme--light.global-banner {
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
}
