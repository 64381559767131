
































@import '@design';

.loader--wrap {
  padding-top: 20vh;
  padding-bottom: 20vh;
  text-align: center;
  background-color: white;
}
