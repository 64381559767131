




















































@import '@design';

.v-btn {
  letter-spacing: 0.12em;
  // border:2px solid white !important;
}
