































.resto-byline {
  a {
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s ease;
    &:hover {
      color: white !important;
    }
  }
}
