@import url('https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap');

$system-default-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

$heading-font-family: 'Poppins', 'Helvetica Neue', $system-default-font-family;
$heading-font-weight: 600;
$content-font-family: 'Poppins', 'Helvetica Neue', $system-default-font-family;
$content-font-weight: 400;

$button-font-family: 'Poppins', 'Helvetica Neue', $system-default-font-family;
$button-font-weight: 600;

%font-heading {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $color-heading-text;
}

%font-content {
  font-family: $content-font-family;
  font-weight: $content-font-weight;
  color: $color-text;
}

%font-button {
  font-family: $button-font-family;
  font-weight: $button-font-weight;
  color: white;
}
