














































































































































































































.content--wrap {
  position: relative;
  .edit-btn.v-btn--top {
    top: -12px;
    right: -25px;
  }
  .save-btn.v-btn--top {
    top: -30px;
    right: -30px;
  }
  > p,
  p.pm-el > p {
    white-space: pre-line;
  }
}

$hover-color: rgba(100, 245, 75, 0.2);

.content {
  opacity: 1;
  transition: opacity 0.3s ease, background-color 0.2s ease;

  &.is-dirty {
    &.hover {
      background-color: rgba(33, 150, 243, 0.3);
    }
  }
  &.saving {
    opacity: 0.4;
  }
}

.content:not(.selected),
.content-placeholder {
  cursor: pointer;
  &:hover,
  &.hover {
    background-color: $hover-color;
  }
}

/* stylelint-disable-next-line */
.content-placeholder {
  position: relative;
  z-index: 10;
  font-size: 0.8rem !important;
  font-style: italic;
  line-height: 1em;
  color: darken(white, 25%) !important;
}

.save-as-deleted {
  position: relative;
  width: 100%;
  font-style: italic;
  // font-size:.8rem !important;
  color: darken(white, 25%) !important;
}
