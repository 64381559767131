

























































































































.layout-text-size-adjuster {
  position: relative;
}
.layout-text-size-adjuster-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  color: white;
  text-decoration: none !important;
}
.text-elem {
  padding: 0.2em 0.4em;
  &.editing {
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: #f2784f;
      transform: translateY(100%);
    }
  }
}
