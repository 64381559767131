































@import '@design';
$min-size: 25px;

.btn--icon-mini {
  width: $min-size;
  min-width: $min-size;
  height: $min-size;
  margin: 0 5px;
  &.btn--xs {
    $min-size: 18px;

    width: $min-size;
    min-width: $min-size;
    height: $min-size;
    .v-icon {
      font-size: 0.7rem;
    }
  }
}
