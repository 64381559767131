

































.offline-alert * {
  font-family: 'Montserrat', sans-serif;
  h2 {
    font-style: italic;
    font-weight: 700;
  }
  p {
    font-weight: 500;
    color: #6b6b6b;
  }
}
