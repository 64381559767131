



















































































































































@use "sass:math";
@import '@design';

.page-section {
  position: relative;
  padding: $size-grid-padding * 2;

  @media (max-width: 960px) {
    padding: $size-grid-padding * 2 math.div($size-grid-padding, 2);
  }

  @media (max-width: 600px) {
    padding: $size-grid-padding * 2 math.div($size-grid-padding, 4);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.25;
  }
}

.page-section--title {
  line-height: 0.85 !important;
}

.section-content {
  z-index: 2;
  padding: math.div($size-grid-padding, 2) math.div($size-grid-padding, 2);
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: math.div($size-grid-padding, 4) math.div($size-grid-padding, 4);
  }

  .not-sm {
    padding: $size-grid-padding $size-grid-padding * 2;
  }
  > .container {
    text-align: left;

    &.fluid {
      @media only screen and (min-width: 1540px) {
        max-width: 1440px;
      }
    }

    @media only screen and (min-width: 1904px) {
      max-width: 1440px;
    }
  }
}
