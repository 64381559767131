






































































































@import '@design';

.btn-base {
  font-weight: bold;
  letter-spacing: 0.12em;
  &.v-btn {
    min-width: 40px;
  }
  &:not(.v-btn--round) {
    border-radius: 0;
  }
}
