












































































































































.layout-elements {
  &.layout-elements--active {
    height: 100%;
    .layout-element--draggable {
      cursor: grab;
    }
  }
}
.layout-elements--empty {
  display: flex;
  align-items: center;

  &.layout-elements--hiding-ui {
    .placeholder {
      display: none;
    }
  }
  &.layout-elements--active {
    justify-content: center;
    border: 1px dotted hsl(0deg, 0%, 80%);
    .placeholder {
      font-size: 1rem;
      line-height: 1.1;
      color: hsl(0deg, 0%, 50%);
      text-transform: uppercase;
      letter-spacing: 0.04em;
    }
  }
  &.drag-target {
    .placeholder {
      display: none;
    }
  }
  &.layout-elements--active {
    min-height: 100px;
  }
} // .layout-elements--empty
