/*

 Fonts

*/

$body-font-family = 'Poppins', serif;

// Import the variables here first, so we can override specific keys/properties
// like $headings.h1.letter-spacing
@import '~vuetify/src/stylus/settings/_variables';

$headings.h1.size = 91px;
$headings.h1.letter-spacing = 0.024em;

$headings.h2.letter-spacing = 0.012em;

$headings.h3.font-family = $body-font-family;

$headings.h4.font-family = $body-font-family;
$headings.h4.letter-spacing = 0.01em;
$headings.h4.font-size = 38px;

$headings.h5.font-family = $body-font-family;
$headings.h5.line-height = 1.5;
$headings.h5.letter-spacing = 0.01em;

$headings.h6.font-family = $body-font-family;
$headings.h6.line-height = 1.3;
$headings.h6.letter-spacing = 0.01em;

h1 {
  font-family: $headings.h1.font-family;
}

h2 {
  font-family: $headings.h1.font-family;
}

h3 {
  font-family: $headings.h1.font-family;
}

h4 {
  font-family: $headings.h1.font-family;
}

h5 {
  font-family: $headings.h1.font-family;
}

h6 {
  font-family: $headings.h1.font-family;
}

/*

 Grid

*/
$grid-breakpoints.lg = 1200px
$container-max-widths.lg = $grid-breakpoints.lg

@import '~vuetify/src/stylus/components/_grid';


/*

 Colors

*/
@import '~vuetify/src/stylus/settings/_colors';

/* DAILY BEET PALETTE */
$colors.db-purple = { base: #940152 };
$colors.db-green = { base: #6cbd7d };
$colors.db-teal = { base: #cfebdf };
$colors.db-orange = { base: #f2784f };
$colors.db-gold = { base: #edcb6e };
$colors.db-cream = { base: #fdf9ec };

/* BLUE GIANT PALETTE */
$colors.bluegiant-blue = { base: #0b1b8f };
$colors.bluegiant-light-blue = { base: #6a8ed1 };
$colors.bluegiant-red = { base: #ff4c3d };


/* BLUE OAK BBQ PALETTE */
$colors.bloak-blue = {base: #1a1d48};
$colors.bloak-piggy-pink = {base: #f74b8a};
$colors.bloak-pink = {base: #f74b8a};
$colors.bloak-mustard = {base: #ffc438};
$colors.bloak-eggnog = {base: #fadda8};
$colors.bloak-pigs-fly-blue = {base: #73d2e9};
$colors.bloak-light-blue = {base: #73d2e9};
$colors.bloak-aquarium-gravel = {base: #00c79d};
$colors.bloak-finger-lickin-brown = {base: #6f1c00};
$colors.bloak-brown = {base: #6f1c00};


/* TREPS PALETTE */
$colors.treps-blue = {base: #0097ce};
$colors.treps-navy = {base: #2c365c};
$colors.treps-steel = {base: #707070};
$colors.treps-orange = {base: #ff945a};
$colors.treps-special-sauce = {base: $ff945a};

/* BUBS PALETTE */
$colors.bubs-purple = {base : #CA6FDA};
$colors.bubs-red = {base : #EA4B2C};
$colors.bubs-wax-paper = {base : #FEE7CB};
$colors.bubs-brown = {base : #FEE7CB};
$colors.bubs-charcoal = {base : #29241A};
$colors.bubs-lettuce-green = {base : #B1F193};
$colors.bubs-green = {base : #B1F193};


$button-height = 50px;
$button-padding = 0 2em;
$button-small-height = 32px;
$button-large-height = 60px;
$button-large-padding = 0 2.5em;
$button-large-font-size = 1.1rem;

.v-btn
  &.jumbo
    font-size: $button-large-font-size * 1.25
    height: $button-large-height * 1.25
    padding: $button-large-padding

@import '~vuetify/src/stylus/components/_buttons';

@import '~vuetify/src/stylus/app';
